import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-0 m-0 justify-content-center" }
const _hoisted_2 = { class: "grid product-modal-content" }
const _hoisted_3 = { class: "grid col-12 md:col-5 lg:col-5 xl:col-3 p-0 m-0 justify-content-center product-image" }
const _hoisted_4 = { class: "grid col-10 md:col-11 lg:col-11 xl:col-11 p-0 m-0 lg:pr-3 xl:pl-3 justify-content-center" }
const _hoisted_5 = {
  key: 1,
  class: "grid col-12 p-0 m-0 mt-3 justify-content-center font-bold text-sm"
}
const _hoisted_6 = ["src", "alt", "onError"]
const _hoisted_7 = {
  key: 1,
  class: "grid col-12 p-0 m-0 mt-3 justify-content-center font-bold text-sm"
}
const _hoisted_8 = {
  key: 2,
  class: "grid col-12 p-0 m-0 mt-3 justify-content-center font-bold text-sm"
}
const _hoisted_9 = { class: "grid col product-info" }
const _hoisted_10 = { class: "grid col-12 mt-2 flex product-info" }
const _hoisted_11 = { class: "grid col-12 p-0 m-0 text-2xl lg:text-base product-title lg:pr-5 text-justify md:text-left" }
const _hoisted_12 = { class: "grid col-12 pb-0" }
const _hoisted_13 = { class: "grid col-12 p-0 product-part-no" }
const _hoisted_14 = {
  key: 0,
  class: "grid col-12 pb-2"
}
const _hoisted_15 = { class: "flex product-ranking" }
const _hoisted_16 = { class: "grid col-12 pt-1 justify-content-center sm:justify-content-start align-items-center pb-0" }
const _hoisted_17 = { class: "flex product-price mr-2 sm:mr-5" }
const _hoisted_18 = { class: "grid col-12 p-0 m-0 characteristics-section" }
const _hoisted_19 = { class: "grid col-12 p-0 m-0 justify-content-center align-items-center lg:justify-content-between" }
const _hoisted_20 = {
  key: 1,
  class: "col-12 grid p-0 m-0"
}
const _hoisted_21 = { class: "product-characteristic" }
const _hoisted_22 = {
  key: 2,
  class: "col product-characteristic-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Galleria = _resolveComponent("Galleria")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      visible: _ctx.showProductDetails,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showProductDetails) = $event)),
      modal: "",
      style: 'width: 75vw',
      breakpoints: { '1700px': '75vw', '1180px': '85vw' },
      class: "product-details-modal",
      escape: "",
      onHide: _ctx.closeDialog
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                    key: 0,
                    style: {"width":"50px","height":"50px"},
                    strokeWidth: "3",
                    class: "mt-2"
                  }))
                : (_openBlock(), _createBlock(_component_Galleria, {
                    key: 1,
                    value: _ctx.productInfo.images,
                    responsiveOptions: _ctx.responsiveOptions,
                    numVisible: _ctx.numImagesVisible,
                    circular: true,
                    class: "grid col-12 custom-galleria"
                  }, {
                    item: _withCtx((slotProps) => [
                      (slotProps.item.image_path)
                        ? (_openBlock(), _createBlock(_component_Image, {
                            key: 0,
                            src: slotProps.item.image_path,
                            alt: slotProps.item.image_desc,
                            class: "col-12 p-0 m-0 image",
                            preview: "",
                            onError: ($event: any) => (slotProps.item.image_path = '')
                          }, null, 8, ["src", "alt", "onError"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, "No images available"))
                    ]),
                    thumbnail: _withCtx((slotProps) => [
                      (slotProps.item.image_path)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: slotProps.item.image_path,
                            alt: slotProps.item.image_desc,
                            class: "col-12 p-0 pt-1",
                            onError: ($event: any) => (slotProps.item.image_path = '')
                          }, null, 40, _hoisted_6))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, "No images available"))
                    ]),
                    _: 1
                  }, 8, ["value", "responsiveOptions", "numVisible"])),
              (_ctx.productInfo.images.length === 0 && !_ctx.loading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "No images available"))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.productInfo.wrap_desc), 1),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", _hoisted_13, [
                  _createTextVNode(" Part# " + _toDisplayString(_ctx.productInfo.part_no) + " " + _toDisplayString(_ctx.productInfo.manufactured_part ? "| Manufactured Part#" : "") + " " + _toDisplayString(_ctx.productInfo.manufactured_part) + " ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partIdFields, (field) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: field.field_no
                    }, "   | " + _toDisplayString(field.label) + " " + _toDisplayString(this.selectedProduct[field.json_name]), 1))
                  }), 128))
                ]),
                (!_ctx.productInfo.total_ratings)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_component_Rating, {
                        modelValue: _ctx.productInfo.rating,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productInfo.rating) = $event)),
                        cancel: false,
                        readonly: "",
                        class: "flex"
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.productInfo.total_ratings) + " ratings | " + _toDisplayString(_ctx.productInfo.answered_questions) + " answered questions ", 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("p", _hoisted_17, " $" + _toDisplayString(_ctx.totalAmount), 1),
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.productQty,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.productQty) = $event)),
                    inputId: "horizontal-buttons",
                    showButtons: "",
                    inputProps: { inputmode: 'numeric'},
                    buttonLayout: "horizontal",
                    step: _ctx.calculateStep(_ctx.selectedProduct),
                    min: 0,
                    decrementButtonClass: "input-number-button",
                    incrementButtonClass: "input-number-button",
                    incrementButtonIcon: "pi pi-plus",
                    decrementButtonIcon: "pi pi-minus",
                    class: "flex product-qty m-0",
                    minFractionDigits: _ctx.useFractionalQuantities ? 1 : undefined,
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => ($event?.target.select())),
                    onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleDecimals($event)))
                  }, null, 8, ["modelValue", "step", "minFractionDigits"]),
                  _createVNode(_component_Button, {
                    label: "Add to Cart",
                    icon: "pi pi-check",
                    class: "flex ml-2 sm:ml-5 add-order-button",
                    onClick: _ctx.addProductToCart
                  }, null, 8, ["onClick"])
                ]),
                _createVNode(_component_Divider),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
                          key: 0,
                          style: {"width":"50px","height":"50px"},
                          strokeWidth: "3"
                        }))
                      : _createCommentVNode("", true),
                    (
                      _ctx.productInfo.product_characteristics &&
                      _ctx.productInfo.product_characteristics.length > 0
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productInfo.product_characteristics, (spec) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: spec,
                              class: "flex col-12 sm:col-6 lg:col-6 xl:col-4 pb-0"
                            }, [
                              _createElementVNode("small", _hoisted_21, _toDisplayString(spec["specs_desc"] || spec["specs"]), 1)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("small", _hoisted_22, _toDisplayString(_ctx.loading ? "  loading..." : ""), 1))
                  ]),
                  _createVNode(_component_Divider)
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ]))
}