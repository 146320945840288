
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import LookupPartNumber from '@/components/Sales/LineItems/LookupPartNumber.vue'

import Utils from '@/utility/utils';
import { FDICT_PARTS } from "@/utility/fdicts/parts";

export default defineComponent({
  name: 'POSPartsTable',
  components: {
    DataTable,
    Column,
    Button,
    Tag,
    Textarea,
    InputText,
    InputNumber,
    LookupPartNumber
  },
  props: {
    class: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    editMode: {
      type: String as () => 'row' | 'cell',
      default: null
    },
    scrollHeight: {
      type: String,
      default: '55vh'
    },
    value: {
      type: Array,
      default: () => [] as any[]
    },
    removable: {
      type: Boolean,
      default: false
    },
    editingRows: {
      type: Array,
      default: () => [] as any[]
    },
    editQtyDirectly: {
      type: Boolean,
      default: false
    },
    showAddToCartBtn: {
      type: Boolean,
      default: false
    },
    partIdFields: {
      type: Array,
      default: () => [] as any[]
    },
    showPaginator: {
      type: Boolean,
      default: false
    },
    first: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      default: 25
    },
  },
  data() {
    return {
      firstItem: 0,
      rowsPerPage: 25,
      data: [] as any[],
      editingItems: [] as any[],
      filters: {
        global: {
         value: null, matchMode: FilterMatchMode.CONTAINS 
        }
      },
      codeArray: [] as any[],
      expandedRows: [] as Array<string>,
      selectedPart: {} as any,
      showLookupPartNumberDialog: false
    }
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      getPosShowKeywordSearch: "mrkControl/posShowKeywordSearch",
      getFieldLabel:"fdict/getFieldLabel"
    }),
    getDataTableClass() {
      return {
        'p-datatable-sm': true,
        [this.class]: this.class
      }
    },
    partNumberLabel(): string {
      return this.getFieldLabel("PARTS", FDICT_PARTS.PART_NO, "Part #");
    },
  },
  watch: {
    editingRows: {
      immediate: true,
      handler(newVal) {
        if (newVal.length === 0) {
          this.editingItems = [];
        }
      }
    },
  },
  created() {
    this.data = this.value;
    this.getPricesByCode();
    this.firstItem = this.first;
    this.rowsPerPage = this.rows;
  },
  emits: ['row-edit-save', 'row-click', 'update-price', 'remove-part', 'on-row-editing-cancel', 'add-to-cart', 'onPage', 'update:rows'],
  methods: {
    isEditing(part: any) {
      return this.editingItems.some((item: any) => item.part_number === part.part_number);
    },
    onRowEditSave(event: any) {
      const index = this.data.findIndex((part: any) => part.part_number === event.data.part_number);
      this.data[index] = event.newData || event.data;
      this.$emit('row-edit-save', event);
    },
    onRowClick(event: any) {
      this.$emit('row-click', event);
    },
    useFractionalQuantities(part: any) {
      return part.fractions === 'Y';
    },
    updatePrice(event: any, part: any) {
      if(event && event.value != part.quantity) {
        part.quantity = event.value;
      }
      this.handleDecimals(event, part);
      const std_price = this.getStdPrice(part);
      const code_price = this.getCodePrice(part);
      if(code_price) {
        part['price'] = code_price
      } else {
        part['price'] = std_price
      }
      this.$emit('update-price', event, part);
    },
    handleDecimals(event: any, part: any) {
      if(this.useFractionalQuantities(part)) {
        Utils.handleLeadingDecimal(event, (value: any) => {part.quantity = value;})
      }
    },
    removePart(event: any, partNumber: string) {
      this.$emit('remove-part', event, partNumber);
    },
    rowClass(rowData: any) {
      return rowData.kitList?.length > 0 ? "" : "no-expander";
    },
    handleOnHandTagText(availQty: number) {
      if (availQty > 0) {
        return `${availQty}`;
      } else {
        return 'Out of Stock';
      }
    },
    handleOnHandTagColor(availQty: number ) {
      return availQty > 0 ? "success" : "danger";
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },
    calculateStep(part: any) {
      if(part.sell_qty) {
        return parseFloat(part.sell_qty);
      }
      return 1;
    }, 
    getStdPrice(price: any) {
      const quantity = price.quantity || 1;
      let price_value = "";
      if(price.std_price_items) {
        if(price.std_price_items.length === 1) {
          price_value = price.std_price_items[0].std_price;
        } else {
          price.std_price_items.forEach((std_item: any) => {
            const std_quantity = parseFloat(std_item.std_qty);
            if(quantity >= std_quantity) {
              price_value = std_item.std_price;
            }
          })
        }
        
        // If there is no valid price with quantitys default to first option.
        if(price_value === "") {
          price_value = price.std_price_items[0].std_price;
        }
      }
      
      if (price_value) {
        return price_value;
      }
      return '0';
    },
    getCodePrice(price: any) {
      const quantity = price.quantity || 1;
      const lowerCaseCodeArray = this.codeArray.map((s: any) => s.toLowerCase());
      const code_prices = price.code_items?.filter((item: any) => lowerCaseCodeArray.includes(item.code.toLowerCase()));
      const cust_code = this.getCustomer.code?.toLowerCase();
      const cust_id = this.getCustomer.cust_id?.toLowerCase();
      let price_value = "" ;
      if (code_prices && code_prices.length > 0) {     
        const code_price = code_prices.find((item: any) => {
          const code = item.code.toLowerCase();
          return code === cust_code;
        });
        const cust_price = code_prices.find((item: any) => {
          const code = item.code.toLowerCase();
          return code === cust_id;
        });
        
        if(code_price) {
          price_value = this.getPriceBasedOnQty(code_price.code_price_items, quantity);
        } else if (cust_price) {
          price_value = this.getPriceBasedOnQty(cust_price.code_price_items, quantity);
        }    
      }
      if (price_value) {
        return price_value;
      }
      return '';
    },
    getPriceBasedOnQty(code_price_items: any, qty: number): string {
      let price = 0
      if(code_price_items) {
        code_price_items.forEach((item : any) => {
          if(!item.code_qty || item.code_qty && qty >= item.code_qty){
            const numericCodePrice = parseFloat(item.code_price);

            if(numericCodePrice > price){
              price = numericCodePrice;
            }
          }
        });
      }
      return price.toString();
    },
    handleRowSave(rowData: any) {
      this.onRowEditSave(rowData);
      this.editingItems = this.editingItems.filter((item: any) => item.part_number !== rowData.data.part_number);
    },
    handleCancelEdit(rowData: any) {
      this.editingItems = this.editingItems.filter((item: any) => item.part_number !== rowData.data.part_number);
      this.$emit('on-row-editing-cancel', rowData.data);
    },
    getPricesByCode() {
      if(this.getCustomer) {
        this.codeArray = [];
        if( this.getCustomer.code ){
          this.codeArray.push(this.getCustomer.code);
        }
        if (this.getCustomer.cust_id !== this.getCustomer.code ){
          this.codeArray.push(this.getCustomer.cust_id);
        }
      }
    },
    buttonColor(quantity: number) {
      return quantity > 0? "background-color: var(--default-button-color)" : "background-color: grey";
    },
    addItem(part: any) {
      this.$emit('add-to-cart', part);
    },
    scrollToFirstItem() {
      let dataTableRef = null as any;
      setTimeout(() => {
        dataTableRef = this.$refs['partsTableRef'];

        if (dataTableRef) {
          const firstItemElement = dataTableRef.$el.querySelector(
            ".p-datatable-wrapper .p-datatable-table .p-datatable-tbody .no-expander:first-child"
          );
          
        if (firstItemElement) {
          firstItemElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      }, 0)
    },
    onPage(event: any) {
      this.scrollToFirstItem();
      this.firstItem = event.first;
      this.$emit('onPage', event);
    },
    showPartInventory(event: any, part: any) {
      event.stopPropagation();
      this.selectedPart = part.part_number;
      this.showLookupPartNumberDialog = true;
    },
  }
})
